<template>
  <div>
    <label>Sample preparation in situ

    </label>
    <extended-radio-group v-model="valueFromProp.preparation_in_situ"
                          :disabled="disabled"
                          :isInvalid="is_preparation_in_situ_invalid"
                          :options="yesNo"></extended-radio-group>
    <div v-if="valueFromProp.preparation_in_situ">
      <label>Sample preparation temperature range
        <span class="grey--text">
                ({{ beamlineSpecification.sample_temperature_in_situ.temperature_range_from }} -
                {{ beamlineSpecification.sample_temperature_in_situ.temperature_range_to }} K)
              </span>
      </label>
      <b-row>
        <b-col>
          <b-input-group :class="{'invalid': validate_input('from')}"
                         append="K"
                         prepend="from"
                         @input="is_preparation_temperature_from_invalid.$touch()">
            <b-form-input v-model="valueFromProp.preparation_temperature_from"
                          :disabled="disabled"
                          :max="beamlineSpecification.sample_temperature_in_situ.temperature_range_to"
                          :min="beamlineSpecification.sample_temperature_in_situ.temperature_range_from"
                          type="number"
                          @paste.prevent/>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group :class="{'invalid': validate_input('to')}"
                         append="K"
                         prepend="to"
                         @input="is_preparation_temperature_to_invalid.$touch()">
            <b-form-input v-model="valueFromProp.preparation_temperature_to"
                          :disabled="disabled"
                          :max="beamlineSpecification.sample_temperature_in_situ.temperature_range_to"
                          :min="beamlineSpecification.sample_temperature_in_situ.temperature_range_from"
                          type="number"
                          @paste.prevent/>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import extendedRadioGroup from '@/components/extended-radio-group';
  import yesNo from '@/json/radioButtonYesNo';

  export default {
    components: { extendedRadioGroup },
    props: {
      value: '',
      disabled: false,
      is_preparation_in_situ_invalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      is_preparation_temperature_from_invalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      is_preparation_temperature_to_invalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      beamlineSpecification: {},
    },
    data() {
      return {
        valueFromProp: this.value,
        yesNo,
      };
    },
    methods: {
      validate_input(input) {
        if (input === 'from') {
          if (this.valueFromProp.preparation_temperature_to === undefined) {
            return this.is_preparation_temperature_from_invalid.$error
              && this.is_preparation_temperature_to_invalid.$dirty;
          }
          return this.is_preparation_temperature_from_invalid.$error;
        }
        if (input === 'to') {
          if (this.valueFromProp.preparation_temperature_from === undefined) {
            return this.is_preparation_temperature_to_invalid.$error
              && this.is_preparation_temperature_from_invalid.$dirty;
          }
          return this.is_preparation_temperature_to_invalid.$error;
        }
      },
    },
    watch: {
      valueFromProp() {
        this.$emit('input', this.valueFromProp);
      },
      'valueFromProp.preparation_in_situ': {
        handler() {
          if (!this.valueFromProp.preparation_in_situ) {
            delete this.valueFromProp.preparation_temperature_from;
            delete this.valueFromProp.preparation_temperature_to;
          }
        },
      },
      value() {
        this.valueFromProp = this.value;
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }
</style>
