<template>
  <div>
    <label> {{ label_text }}
      <span class="grey--text">
          ({{ beamlineSpecification.temperature_range.temperature_range_from }} -
          {{ beamlineSpecification.temperature_range.temperature_range_to }} K)
        </span>
    </label>
    <b-row>
      <b-col>
        <b-input-group :class="{'invalid': validate_temperature_input('from')}"
                       append="K"
                       prepend="from"
                       @input="value_from_invalid.$touch()">
          <b-form-input v-model="valueFromProp[value_from]"
                        :max="beamlineSpecification.temperature_range.temperature_range_to"
                        :min="beamlineSpecification.temperature_range.temperature_range_from"
                        :readonly="disabled"
                        type="number"
                        @paste.prevent></b-form-input>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group :class="{'invalid': validate_temperature_input('to')}"
                       append="K"
                       prepend="to"
                       @input="value_to_invalid.$touch()">
          <b-form-input v-model="valueFromProp[value_to]"
                        :max="beamlineSpecification.temperature_range.temperature_range_to"
                        :min="beamlineSpecification.temperature_range.temperature_range_from"
                        :readonly="disabled"
                        type="number"
                        @paste.prevent></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: {
      value: '',
      disabled: false,
      value_from_invalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      value_to_invalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      label_text: {
        type: String,
        default: 'Measurement temperature range',
      },
      value_from: {
        type: String,
        default: 'measurement_temperature_from',
      },
      value_to: {
        type: String,
        default: 'measurement_temperature_to',
      },
      beamlineSpecification: {},
    },
    data() {
      return { valueFromProp: this.value };
    },
    methods: {
      validate_temperature_input(input) {
        if (input === 'from') {
          if (this.valueFromProp[this.value_to] === undefined) {
            return this.value_from_invalid.$error && this.value_to_invalid.$dirty;
          }
          return this.value_from_invalid.$error;
        }
        if (input === 'to') {
          if (this.valueFromProp[this.value_from] === undefined) {
            return this.value_to_invalid.$error && this.value_from_invalid.$dirty;
          }
          return this.value_to_invalid.$error;
        }
      },
    },
    created() {

    },
    watch: {
      valueFromProp() {
        this.$emit('input', this.valueFromProp);
      },
      value() {
        this.valueFromProp = this.value;
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }
</style>
