<template>
  <base-icon-round-button class="mb-2 bg-warning"
                          title="Remove"
                          v-on="$listeners">
    <base-material-design-icon icon="remove"/>
  </base-icon-round-button>
</template>

<script>
  import BaseIconRoundButton from '@/components/baseComponents/baseIcons/BaseIconRoundButton';
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';

  export default {
    name: 'BaseIconRoundButtonRemove',
    components: {
      BaseMaterialDesignIcon,
      BaseIconRoundButton,
    },
  };
</script>

<style scoped>

</style>
