<template>
  <div>
    <label>Photon energy range
      <slot></slot>
      <span class="grey--text">
        ({{ beamlineSpecification.energy_range.energy_range_from }} -
         {{ beamlineSpecification.energy_range.energy_range_to }} {{ unit }})</span></label>
    <b-row>
      <b-col>
        <b-input-group :append="unit"
                       :class="{'invalid': validate_energy_input('from')}"
                       prepend="from">
          <b-form-input v-model="valueFromProp[value_from]"
                        :max="beamlineSpecification.energy_range.energy_range_to"
                        :min="beamlineSpecification.energy_range.energy_range_from"
                        :readonly="disabled"
                        :step="step"
                        type="number"
                        @paste.prevent
                        @blur.native="is_photon_energy_from_invalid.$touch()"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group :append="unit"
                       :class="{'invalid': validate_energy_input('to')}"
                       prepend="to">
          <b-form-input v-model="valueFromProp[value_to]"
                        :max="beamlineSpecification.energy_range.energy_range_to"
                        :min="beamlineSpecification.energy_range.energy_range_from"
                        :readonly="disabled"
                        :step="step"
                        type="number"
                        @paste.prevent
                        @blur.native="is_photon_energy_to_invalid.$touch()"></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: {
      value: '',
      disabled: false,
      is_photon_energy_from_invalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      is_photon_energy_to_invalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      value_from: {
        type: String,
        default: 'photon_energy_from',
      },
      value_to: {
        type: String,
        default: 'photon_energy_to',
      },
      unit: {
        type: String,
        default: 'eV',
      },
      step: {
        type: String,
        default: '1',
      },
      beamlineSpecification: {},
    },
    data() {
      return { valueFromProp: this.value };
    },
    methods: {
      validate_energy_input(input) {
        if (input === 'from') {
          if (this.valueFromProp[this.value_to] === undefined) {
            return this.is_photon_energy_from_invalid.$error && this.is_photon_energy_to_invalid.$dirty;
          }
          return this.is_photon_energy_from_invalid.$error;
        }
        if (input === 'to') {
          if (this.valueFromProp[this.value_from] === undefined) {
            return this.is_photon_energy_to_invalid.$error && this.is_photon_energy_from_invalid.$dirty;
          }
          return this.is_photon_energy_to_invalid.$error;
        }
      },
    },
    watch: {
      valueFromProp() {
        this.$emit('input', this.valueFromProp);
      },
      value() {
        this.valueFromProp = this.value;
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }
</style>
