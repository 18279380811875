<template>
  <div class="flex-column d-flex align-content-space-between">
    <b-row v-for="(_, index) in value_from_prop"
           :key="index"
           class="space-between-rows">

      <b-col class="no-stack-column">
        <b-input-group :append="options.first.append"
                       :prepend="options.first.prepend"
                       class="mb-1">
          <b-form-input v-model="value_from_prop[index][0]"
                        :class="{'invalid': value_from_prop[index][0] === ''}"
                        :readonly="disabled"
                        type="text"
                        @input="$emit('input', value_from_prop)"/>
        </b-input-group>
      </b-col>

      <b-col class="no-stack-column">
        <b-input-group :append="options.second.append"
                       :prepend="options.second.prepend"
                       class="mb-1">
          <b-form-input v-model="value_from_prop[index][1]"
                        :class="{'invalid':  value_from_prop[index][1] === ''}"
                        :readonly="disabled"
                        :type=type
                        v-bind="options.second.prop"
                        @input="$emit('input', value_from_prop)"/>
        </b-input-group>
      </b-col>

      <b-col cols="auto pl-0 d-flex">
        <base-icon-round-button-remove v-if="value_from_prop.length > 1 && !disabled"
                                       @click="removeSelected(index)"/>
      </b-col>
    </b-row>

    <base-icon-round-button-add v-if="multiselect && !disabled"
                                class="justify-center mx-auto"
                                @click="addEmpty"/>
  </div>
</template>

<script>
  import BaseIconRoundButtonAdd from '@/components/baseComponents/baseIcons/BaseIconRoundButtonAdd';
  import BaseIconRoundButtonRemove from '@/components/baseComponents/baseIcons/BaseIconRoundButtonRemove';

  export default {
    components: {
      BaseIconRoundButtonRemove,
      BaseIconRoundButtonAdd,
    },
    props: {
      value: [String, Array],
      disabled: {
        type: Boolean,
        default: false,
      },
      options: Object,
      multiselect: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        type: this.options.second.type,
        value_from_prop: this.value,
      };
    },
    mounted() {
      if (this.value_from_prop === undefined) {
        this.value_from_prop = [];
        this.$emit('input', this.value_from_prop);
        this.value_from_prop.push(['', '']);
      }
    },
    destroyed() {
      this.value_from_prop = undefined;
    },
    methods: {
      addEmpty() {
        this.value_from_prop.push(['', '']);
      },
      removeSelected(index) {
        this.value_from_prop.splice(index, 1);
      },

    },
  };
</script>

<style lang="scss" scoped>
  .invalid {
    border: 2px solid red;
  }

  .no-stack-column {
    min-width: 250px;
  }

  .space-between-rows {
    margin-bottom: 0.5rem;
    @media (max-width: 950px) {
      margin-bottom: 1.5rem;
    }
  }
</style>
