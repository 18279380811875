<template>
  <div class="multiselect-margin">
    <b-row v-if="!disabled">
      <b-col>
        <b-form-checkbox-group v-model="propValue"
                               :class="groupClass"
                               :stacked="stacked"
                               buttons
                               class="checkbox-container"
                               @input="selectionChanger(propValue, unselectBtn)">
          <b-form-checkbox v-for="(option, index) in options"
                           :key="index"
                           v-bind="getFinalProperties(option)"
                           :class="{'invalid': isInvalid.$error}"
                           :value="option.name"
                           @input="isInvalid.$touch()">
            <span v-text="option.text || option.name"/>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <div v-else>
      <div v-for="selected in propValue"
           :key="selected"
           :class="disabledClass"
           class="font-weight-bold mt-3 mr-3 mb-4 btn btn-info">
        <span v-text="selected"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        default: () => [],
        required: true,
      },
      defaultSelect: {
        type: Array,
        default: () => [],
        validator: (value) => value.every((element) => typeof element === 'string'),
      },
      isInvalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      stacked: {
        type: Boolean,
        default: false,
      },
      groupClass: {
        type: String,
        default: '',
      },
      disabledClass: {
        type: String,
        default: '',
      },
      checkboxesProperties: {
        type: Object,
        default: () => ({}),
      },
      unselectBtn: {
        type: String,
        default: '',
      },
    },
    data() {
      return { propValue: this.value };
    },
    watch: {
      value: {
        handler() {
          this.$set(this, 'propValue', this.value);
        },
      },
      propValue() {
        this.$emit('input', this.propValue);
      },
    },
    methods: {
      setDefaultSelection() {
        if (this.defaultSelect.length) {
          this.$set(this, 'propValue', this.defaultSelect);
        }
      },
      selectionChanger(value_to_set, unselect_btn) {
        if (this.unselectBtn === '') return;
        const unselect_btn_idx = value_to_set.indexOf(unselect_btn);
        if (unselect_btn_idx > 0) {
          this.$set(this, 'propValue', [unselect_btn]);
        } else if (unselect_btn_idx < 0) {
          this.$set(this, 'propValue', value_to_set);
        } else {
          if (value_to_set.length > 1) {
            value_to_set.splice(unselect_btn_idx, 1);
          }
          this.$set(this, 'propValue', value_to_set);
        }
      },
      getFinalProperties(option) {
        return {
          style: option.style,
          buttonVariant: option.buttonVariant || 'outline-info',
          ...this.checkboxesProperties,
        };
      },
    },
    created() {
      this.setDefaultSelection();
    },
  };
</script>

<style scoped>
  label {
    margin: 0 1rem 0 1rem;
    border-radius: 0.4rem !important;
  }

  .invalid {
    border: 2px solid red;
  }

  ::v-deep .checkbox-container {
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0 !important;
  }

  ::v-deep .checkbox-container > * {
    margin-left: 0;
    margin-right: 0;
  }

  .multiselect-margin {
    margin-bottom: 1.5rem;
  }
</style>
